import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaCalendarAlt, FaFileAlt, FaLock, FaUser, FaMagic } from "react-icons/fa";
import './TabSection.css'

const tabs = [
  { id: "scheduling", icon: <FaCalendarAlt />, title: "Calendar scheduling", description: "Accept online bookings. Automate your reminders.", image: "/cal-f-2.jpg" },
  { id: "documentation", icon: <FaFileAlt />, title: "Patient notes and documentation", description: "Streamline your intake, notes, and documentation.", image: "/patient-screen.jpg" },
  { id: "billing", icon: <FaLock />, title: "Easy and secure billing ", description: "Simple, fast, and digital.", image: "/billing-screen.jpg" },
  { id: "client-portal", icon: <FaUser />, title: "Appointment reminders", description: "Seamless care with an easy-to-use client app.", image: "/reminders-screen.jpg" },
  // { id: "ai-automation", icon: <FaMagic />, title: "Powerful AI & automations", description: "Reduce busy work so you can do your best work.", image: "/ai.webp" }
];

export default function TabSection() {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div className="tabsSection container">
      <div className="innerTabsBlock contentTabsBlock">
        {/* <h2 className="text-3xl font-bold text-purple-800">Save More <span>Time</span> Do More <span>Business</span></h2> */}
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tabsBtn ${
              activeTab.id === tab.id ? "activeTab bg-white shadow-md" : "hover:bg-purple-200"
            }`}
            onClick={() => {
              console.log("Clicked:", tab.id);
              setActiveTab(tab);
            }}
          >
            <div className="mr-4 text-purple-600 text-2xl iconBlock">{tab.icon}</div>
            <div className="contentBlockTabs">
              <h3 className="font-semibold text-lg">{tab.title}</h3>
              <p className="text-gray-600 text-base">{tab.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="innerTabsBlock imgBlockTabs">
        <img src={activeTab.image} />
        {/* <AnimatePresence mode="wait">
          <motion.img
            key={activeTab.id}
            src={activeTab.image}
            alt={activeTab.title}
            className="w-80 h-80 object-cover rounded-lg shadow-lg"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.5 }}
          />
        </AnimatePresence> */}
      </div>
    </div>
  );
}
