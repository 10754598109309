import { Row, Col } from 'antd';
import { Link } from "react-router-dom";
// import OurProvidersImage from '../../assets/OurProvidersImage.png'
import OurProvidersImage from '../../assets/app-1-s.png'
import './OurProviders.css'

const OurProviders = () => {

    return (
        <>
            <div className="provider-section">
                <Row gutter={[2, 16]} align="middle">
                    <Col xs={24} md={12}>
                        <div className="provider-content">
                            <h3 style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: 700, color: "#252B42" }}>FEATURES</h3>
                            <Row gutter={[16, 2]} >
                                <Col xs={24} sm={12}>
                                    <ul >
                                        <li>Therapists</li>
                                        <li>Acupuncturists</li>
                                        <li>Counselors</li>
                                        <li>Speech Pathologists</li>
                                        <li>Social Workers</li>
                                    </ul>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <ul >
                                        <li>Postnatal Consultants</li>
                                        <li>Massage therapist</li>
                                        <li style={{ whiteSpace: "break-spaces" }}>Dietitians and Nutritionists</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="provider-image">
                            <img width="550px" height="370px" src={OurProvidersImage} alt="Image" />
                            <div style={{ marginTop: "20px" }}>
                                {/* <p style={{ color: "#2E3E5C", fontSize: "14px" }}>The New Product</p> */}
                                <h2 style={{ fontWeight: 700, color: "#2E3E5C", fontFamily: "Poppins", }}>Bluu Health</h2>
                                <Link style={{ color: "#2E3E5C", fontSize: "14px", textDecoration: "underline" }}>Features overview</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default OurProviders