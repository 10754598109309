import React from 'react'
import PricingCard from '../components/pricing/PricingCard';
import LandingForm from '../components/landingform/LandingForm';
import UpdateBanner from '../components/Banner/UpdateBanner';
import BusinessSection from '../components/business/BusinessSection';
import TabSection from '../components/tabsection/TabSection';
import OurProducts from '../dropdown/ourProducts/OurProducts';
import TestimonialSlider from '../components/testimonial/TestimonialSlider';
import CTABlock from '../components/CTABlock/CTABlock';

const Home = () => {
  return (
    <>
      <UpdateBanner />
      <TabSection />
      
      <BusinessSection />
      <LandingForm />
      <TestimonialSlider />
      <br />
      <br />
      {/* <PricingCard /> */}
      <CTABlock />
      {/* <OurProducts /> */}
    </>
  )
}

export default Home



