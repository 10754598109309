import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from '../../assets/footerlogo.png'
import updateHealth from '../../assets/updateHealth.jpg'
import { FaFacebook, FaTwitter, FaInstagram, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import { Row, Col, Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className='footer_custom'>
      <Container>
        <Row>
          <Col sm={6} md={3}>
            <div className='column col1'>
              <div className='theme_logo'>
                <img src={logo} />
              </div>
              <p>For further queries: </p>

              <ul className='contact_list'>
                <li><a href='mailto:info@bluuhealth.com'><FaEnvelope /> info@bluuhealth.com</a></li>
                {/* <li><a href='tel:480-275-3106'><FaPhoneAlt /> 480-275-3106</a></li>
               <li><FaMapMarkerAlt /> 617 East Avenue Tempe, AZ 85282</li>*/}
              </ul>
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className='column links padding-left'>
              <h3>Quick Links</h3>

              <ul>
                {/* <li><Link to='/faq'>FAQs</Link></li> */}
                <li><Link to='/careers'>Careers</Link></li>
                <li><Link to='/subscription'>Join BluuHealth</Link></li>
              </ul>
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className='column links padding-left'>
              <h3>Our Services</h3>
              <div style={{ columnCount: 2 }}>
                <ul>
                  <li style={{ color: 'white' }}>Insurance filing</li>
                  <li style={{ color: 'white' }}>Telehealth video appointments</li>
                  <li style={{ color: 'white' }}>Practice organization</li>
                  <li style={{ color: 'white' }}>Billing</li>
                </ul>
                <ul>
                  <li style={{ color: 'white' }}>Fully HIPAA- Complaint</li>
                  <li style={{ color: 'white' }}>Customizable client template</li>
                  <li style={{ color: 'white' }}>+ more</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className='column contact padding-left'>
              <h3 style={{ color: "#fff" }}>For Latest Updates</h3>
              <div className='contact_no socialInfo'>
                <div className='number_block'>
                  <div className='number-block-svg' onClick={()=> window.open('https://www.facebook.com/profile.php?id=61571952466071&mibextid=wwXIfr', '_blank')}>
                    <FaFacebook />
                  </div>
                  <div className='number-block-svg' onClick={()=> window.open('https://www.instagram.com/bluu.health/', '_blank')}>
                    <FaInstagram />
                  </div>
                  {/* <div className='number-block-svg'>
                    <FaLinkedin />
                  </div> */}
                  {/* <div className='number_info'>
                    <span>For Latest Updates</span>
                    <h4>bluuhealthcare</h4>
                  </div> */}
                </div>
              </div>

            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className='footer__bottom_bar'>
              <ul>
                <li><a href='#'>Privacy Policy</a></li>
                <li><a href='#'>Terms & Conditions</a></li>
              </ul>
              <h3>Bluu Health copyright</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer