import React from 'react'
import './CTABlock.css'
import { ArrowRight } from 'lucide-react';

const CTABlock = () => {
  return (
    <>
    <div className='ctaSectiond'>
      <div className='container'>
        <div className='row'>
            <div className='col-md-8'>
                <div className='contentAreaCTA'>
                    <h2><span>Struggling to make a</span> <span className='clr_Two'>decision ?</span></h2>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='btnCTABLOCK'>
                  <button onClick={() => { window.location = process.env.REACT_APP_PORTAL_URL + '/auth/login'; }}>Try Bluu for free <ArrowRight /></button>
                </div>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default CTABlock
