import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import parse from 'html-react-parser';
import './PricingPage.css'

gsap.registerPlugin(ScrollTrigger);

export default function PricingPage() {
  const [billingCycle, setBillingCycle] = useState("monthly");

  const pricingData = {
    monthly: {
      price: "$11",
      billed: 'Billed Monthly',
      oldPrice: "$22",
    //   details: "$11/ month per user /- cross out $22 (It's $22 after 6 months)",
      handleFun: () => window.location = `${process.env.REACT_APP_PORTAL_URL}/auth/subscribe?planType=monthly`
    },
    annually: {
      price: "$132",
      billed: 'Billed Annually',
      oldPrice: "$264",
      details: "$132 Annually 50% off $264",
      handleFun: () => window.location = `${process.env.REACT_APP_PORTAL_URL}/auth/subscribe?planType=yearly`
    },
  };

  const checkIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>`
  const btnArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up-right"><path d="M7 7h10v10"/><path d="M7 17 17 7"/></svg>`


  const rightColumnRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <>
        <section className="container pringPage">
            <h2 className="heading">Our Pricing</h2>
            <h3 className="headingSubP">explore all our features with a 30-day trial. Once it ends,
            choose a plan that’s right for you.</h3>

            <div className="plansBlock">

                <div className={`lanDetailsP ${isFixed ? "isFixedLeftCol-No" : ""}`}>
                    <div className="pricingPointsCardBlock">
                        <h3>Billing & Payments</h3>
                        <ul>
                            <li>Secure online payments</li>
                            <li>Credit card processing</li>
                            <li>AutoPay options</li>
                            <li>Automated invoicing</li>
                        </ul>
                    </div>

                    <div className="pricingPointsCardBlock">
                        <h3>Scheduling & Appointments</h3>
                        <ul>
                            <li>Unlimited client management</li>
                            <li>Digital intake forms</li>
                            <li>Telehealth integration</li>
                            <li>Confirm or cancel via text</li>
                            <li>Group appointment scheduling</li>
                            <li>Customizable calendar view</li>
                            <li>Automated appointment reminders</li>
                            <li>Client alerts & notifications</li>
                        </ul>
                    </div>

                    <div className="pricingPointsCardBlock">
                        <h3>Documentation & Notes</h3>
                        <ul>
                            <li>Progress note tracking</li>
                            <li>Template import</li>
                            <li>ICD-10 code reference</li>
                        </ul>
                    </div>

                    <div className="pricingPointsCardBlock">
                        <h3>Additional Features</h3>
                        <ul>
                            <li>HIPAA compliant</li>
                            <li>Customer support</li>
                            <li>Simple client data import</li>
                            <li>Free document storage</li>
                            <li>Analytics dashboard</li>
                            <li>Admin calendar & scheduling tools</li>
                            <li>Income allocation review</li>
                        </ul>
                    </div>
                </div>

                <div className={`pricingCardDivBlock ${isFixed ? "isFixedRightCol-No" : ""}`} ref={rightColumnRef}>
                    {/* <motion.div
                        className="bg-orange-200 text-orange-700 px-4 py-2 rounded-full mb-4"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        50% off for 6 months
                    </motion.div> */}

                    <div className="btnPriceTbas">
                        <button
                        className={`px-4 py-2 rounded ${
                            billingCycle === "monthly" ? "active" : ""
                        }`}
                        onClick={() => setBillingCycle("monthly")}
                        >
                        Monthly
                        </button>
                        <button
                        className={`px-4 py-2 rounded ${
                            billingCycle === "annually" ? "active" : ""
                        }`}
                        onClick={() => setBillingCycle("annually")}
                        >
                        Annually
                        </button>
                    </div>
                    
                    {billingCycle && billingCycle === "monthly" && <div class="bubble-badge">50% off for 6 months for monthly subscriptions</div>}
                    <motion.div
                        key={billingCycle}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="prickBlockDiv"
                    >
                        
                        <div className="priceBlockHeadingTop">
                            <h2 className="priceHeading">{pricingData[billingCycle].price} <span>{pricingData[billingCycle].billed}</span></h2>
                            <span className="oldPriceData">{pricingData[billingCycle].oldPrice}</span>
                            <p className="priceSubHeading">{pricingData[billingCycle].details}</p>
                        </div>
                        <ul className="priceList">
                        <li>{parse(checkIcon)} Unlimited clients</li>
                        <li>{parse(checkIcon)} Telehealth</li>
                        <li>{parse(checkIcon)} Client portal</li>
                        <li>{parse(checkIcon)} Customizable progress notes</li>
                        <li>{parse(checkIcon)} Automated billing and online payments</li>
                        <li>{parse(checkIcon)} Secure client messaging</li>
                        <li>{parse(checkIcon)} Dedicated account manager</li>
                        <li>{parse(checkIcon)} Automated reminders</li>
                        <li>{parse(checkIcon)} Customizable template import</li>
                        <li>{parse(checkIcon)} Unlimited storage</li>
                        <li>{parse(checkIcon)} Group scheduling</li>
                        <li>{parse(checkIcon)} Analytic dashboard</li>
                        <li>+ More</li>
                        </ul>
                        <div className="priceBlockBTN">
                            <button onClick={pricingData[billingCycle].handleFun} className="btnPrice">Get Started {parse(btnArrow)}</button>
                        </div>
                    </motion.div>
                </div>
            </div>

        </section>
       
    
    </>
  );
}
