import React, { useState } from 'react'
import { Row, Col, Card } from 'antd';
import businessFrame from '../../assets/businessFrame.png'
import businessFrame2 from '../../assets/businessFrame2.png'
import businessFrame3 from '../../assets/businessFrame3.png'
import businessFrame4 from '../../assets/businessFrame4.png'
import './BusinessSection.css'
import BusinessOverView from './overview/BusinessOverView';

const BusinessSection = () => {
    const [activeKey, setActiveKey] = useState('Client')

    const cardData = [{
        key: 'Client',
        title: 'Client customisation',
        content: `Bluu empowers users to tailor every aspect of their business operations. Whether it's patient origination, billing, auto- pay, or notes, Bluu provides the flexibility to customize your workflow. Manage your practice seamlessly from start to finish with our user - friendly platform`
    },
    {
        key: 'Admin',
        title: 'Admin Task Organization',
        content: `Bluu enables users to organize clients according to their schedules, reducing no-shows and simplifying the client management experience for both users and clients.`
    },
    {
        key: 'Streamlines',
        title: 'Streamlines billing & Insurance',
        content: `Bluu simplifies the billing process while ensuring security. Users can enable auto-pay and provide clients with easy payment options, including reminders and accessible payment methods.`
    },
    {
        key: 'HIPPA',
        title: 'HIPPA - Complaint',
        content: `Bluu's practice management software is designed with top-tier security protocols, ensuring data privacy, network defense, and robust safeguards to protect the confidentiality and security of all health information.`
    },
    ]
    const businessOverView = {
        Client: {
            image: businessFrame,
            title: `Provide qualiy, HIPAA- complaint care`,
            content: `Bluu is an all-in-one EHR software that enables users to manage their business
                        seamlessly from start to finish.Our platform simplifies scheduling, billing, record- keeping, and telehealth sessions, all in one place.`
        },
        Admin: {
            image: businessFrame3,
            title: `Simplify your workflow with Bluu`,
            content: `With integrated tools across desktop and mobile, you can run smooth and interactive telehealth sessions, customize treatment plans, and securely communicate with clients via the Client Portal. You can also add unlimited clients for free`
        },
        Streamlines: {
            image: businessFrame2,
            title: `Simplify your workflow with Bluu`,
            content: `With integrated tools across desktop and mobile, you can run smooth and interactive telehealth sessions, customize treatment plans, and securely communicate with clients via the Client Portal. You can also add unlimited clients for free`
        },
        HIPPA: {
            image: businessFrame4,
            title: `Simplify your workflow with Bluu`,
            content: `With integrated tools across desktop and mobile, you can run smooth and interactive telehealth sessions, customize treatment plans, and securely communicate with clients via the Client Portal. You can also add unlimited clients for free`
        },


    }
    return (
        <>
            <div className='business-container'>
                <h1 style={{ color: "#1E1F4B ", fontWeight: 700, fontFamily: "Poppins" }}>
                    Save More <span style={{ color: '#706FE5' }}>Time</span> Do More <span style={{ color: "#706FE5" }}>Business</span>
                </h1>
                <div className="business-section">
                    <Row gutter={[2, 16]} align="middle">
                        <Col xs={24} md={12}>
                            <div className="business-content">
                                <Row gutter={[16, 2]} style={{ marginTop: '20px' }}>
                                    {cardData?.map((el, i) => {
                                        return (
                                            <>
                                                <Col key={el?.key} xs={24} sm={12}>
                                                    <Card onClick={() => setActiveKey(el?.key)} className={`custom-card ${activeKey === el?.key && `active-card`}`}>
                                                        <h3 style={{ fontWeight: 600 }}>{el?.title}</h3>
                                                        <p style={{ fontSize: '14px', marginTop: "25px" }}>
                                                            {el?.content}
                                                        </p>
                                                    </Card>
                                                </Col>
                                            </>
                                        )
                                    })}
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <BusinessOverView data={businessOverView[activeKey]} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default BusinessSection