import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useRef } from 'react';
import './TestimonialSlider.css'

const testimonials = [
  {
    id: 1,
    name: "Sarah, LMFT",
    text: "As a therapist, my day is really packed and I don't have time to spend hours on administrative tasks. Bluu has been a game-changer for my private practice. Scheduling, notes, and billing used to take up so much of my time, but now everything is streamlined in one easy-to-use platform. The HIPAA-compliant client portal has also improved communication with my clients, making their experience smoother. I finally feel like I can focus on my clients rather than admin work!",
    // image: "https://images.squarespace-cdn.com/content/v1/631ba8eed2196a6795698665/c62f320d-a449-4723-8705-ce38f51049ec/2022-08-10-Trinet-Monticalvo-Caleb-0950.jpg",
    customClass: 'showContent'
  },
//   {
//     id: 2,
//     name: "Jane Smith",
//     text: "Bluu has completely streamlined my therapy practice. From easy scheduling to secure note-taking and billing, everything I need is in one place. The client portal is a huge plus—it allows my clients to book sessions, fill out intake forms, and make payments effortlessly. Since switching to Bluu, I spend less time on admin work and more time focusing on my clients. I highly recommend it to any therapist looking for a reliable and intuitive practice management solution!",
//     // image: "https://www.denvercorporateheadshots.net/wp-content/uploads/professional-headshots-aurora-1.jpg",
//     customClass: 'showContent'
//   },
  {
    id: 3,
    name: "Emily, RD",
    text: "Bluu has made managing my nutrition practice so much easier. The integrated scheduling and billing system keeps everything organized, and the secure client portal allows my patients to communicate with me easily.  I no longer have to juggle multiple platforms—Bluu does it all!",
    // image: "https://robincollettephotography.com/wp-content/uploads/sites/7154/2021/04/sacramento_professional_headshot_tips-1.jpg",
    customClass: 'showContent'
  },
  {
    id: 4,
    name: "Rio, LCSW",
    text: "Bluu has completely streamlined my therapy practice. From easy scheduling to secure note-taking and billing, everything I need is in one place. The client portal is a huge plus—it allows my clients to book sessions, fill out intake forms, and make payments effortlessly. Since switching to Bluu, I spend less time on admin work and more time focusing on my clients. I highly recommend it to any therapist looking for a reliable and intuitive practice management solution!",
    // image: "https://images.squarespace-cdn.com/content/v1/631ba8eed2196a6795698665/c62f320d-a449-4723-8705-ce38f51049ec/2022-08-10-Trinet-Monticalvo-Caleb-0950.jpg",
    customClass: 'showContent'
  },
//   {
//     id: 5,
//     name: "Mark Wilson",
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
//     // image: "https://www.denvercorporateheadshots.net/wp-content/uploads/professional-headshots-aurora-1.jpg",
//     customClass: 'showContent'
//   }

];

export default function TestimonialSlider() {
  const swiperRef = useRef(null);

  return (
    <div className="relative w-full max-w-2xl mx-auto p-4 testimonialSection">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={20}
        slidesPerView={3}
        loop={true}
        // autoplay={{
        //     delay: 3000, // 3 seconds delay
        //     disableOnInteraction: false, // Autoplay will not stop on interaction
        //   }}
        breakpoints={{
            0: { slidesPerView: 1 }, // Mobile: 1 slide
            768: { slidesPerView: 2 }, // Tablets: 2 slides
            1024: { slidesPerView: 3 }, // Desktop: 4 slides
          }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <motion.div 
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className={`testiminialBlcok ${testimonial.customClass}`}
            >
              {testimonial && testimonial.image && <img 
                src={testimonial.image} 
                alt={testimonial.name} 
                className="w-16 h-16 rounded-full mb-4"
              />}
              <div className='opacityBlockTestiminail'>
                <p className="text-testimonial">{testimonial.text}</p>
                <h4 className="text-name">- {testimonial.name}</h4>
              </div>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <button 
        className="btnArrowtestiminial backBtn" 
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <ArrowLeft size={24} />
      </button>
      <button 
        className="btnArrowtestiminial nextBtn" 
        onClick={() => swiperRef.current?.slideNext()}
      >
        <ArrowRight size={24} />
      </button> */}
    </div>
  );
}
